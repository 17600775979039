import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import gsap from 'gsap';

import NavItems from './NavItems';

const NavContainer = styled.div`
   position: relative;
    
    .nav_button {
        cursor: pointer;
        display: inline-block;
        height: 40px;
        width: 40px;
        /* padding: 4px 5px; */
        text-align: center;
        position: absolute; 
        /* right: 10px; */
        z-index: 1000;
        margin-top: 2px;
        margin-left: 2rem;
        /* background-color: ${props => props.color ? props.color : props.theme.colors.dark}; */
    }

    .nav_bg {
        background-color: transparent;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        position: absolute;
        top: -1rem;
        right: -5rem;
        z-index: 110;
    
        transition: transform .4s;
    }
    
    .nav {
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 250;
        top: 0;
        left: 0;
        background-color: transparent;
        opacity: 0;
        width: 0;
        overflow: hidden;
    
        transition: all .1s;
    }
    
    #nav_toggle {
        display: none;
    }
    
    #nav_toggle:checked ~ .nav_bg {
        background-color: ${props => props.theme.colors.body};
        transform: scale(150);
    }
    
    #nav_toggle:checked ~ .nav{
        opacity: 1;
        width: 100%;
    }

    #nav_toggle:checked + .nav_button .nav_icon#top-bar2{
        background-color: transparent !important;
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar1 {
        top: 7px;
        transform: rotate(135deg);
        background-color: ${props => props.theme.colors.dark} !important;
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar3{
        top: 7px;
        transform: rotate(-135deg);
        background-color: ${props => props.theme.colors.dark} !important;
    }
    
    .nav_icon {
        position: absolute;
        /* margin-top: 15px; */
        top: 0;
        left: 0;
    
        &
        /* &::before,
        &::after  */
        {
            display: inline-block;
            height: 3px;
            width: 30px;
            border-radius: 50px;
            background-color: ${props => props.color ? props.theme.colors.body : props.theme.colors.dark};
            transition: all .2s;
        }

        &#top-bar2 {
            top: 7px;
        }

        &#top-bar3 {
            top: 14px;
        }
        
    
        /* &::before, &::after {
            content: "";
            position: absolute;
            left: 0;
        }
    
        &::before { top: -7px; }
        &::after { top: 7px; } */
        
    }
    .nav_button:hover .nav_icon#top-bar1 {
        top: -2px;
    }
    .nav_button:hover .nav_icon#top-bar3 {
        top: 16px;
    }
    
`
 
export default ({color}) => {

    const [checked, setChecked] = useState(false);

    const handleCheck = () => {
        setChecked(prev => !prev);
        const tl = gsap.timeline();
        if (!checked) {
            if (window.innerWidth > 960) {
            tl.fromTo('.nav-list-item', {y: 800, autoAlpha: 0}, {y: 0, autoAlpha: 1, duration: .5, ease: 'back.out', stagger: .1})
        }
        }
    } 
 
 return (
  <NavContainer color={color}>
     <input type="checkbox" onChange={handleCheck} id="nav_toggle" />
    <label htmlFor="nav_toggle" className="nav_button">
        <span className="nav_icon" id="top-bar1">&nbsp;</span>
        <span className="nav_icon" id="top-bar2">&nbsp;</span>
        <span className="nav_icon" id="top-bar3">&nbsp;</span>
    </label>
    <div className="nav_bg"></div>
    <nav className="nav">
      <NavItems active="1" />
    </nav>
  </NavContainer>
 )
}