/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider, 
  CssBaseline} from '@material-ui/core';
import { theme } from "../utils/theme"

import favicon from '../images/favicon.ico';

import Header from "./header"
import "../css/main.scss"
import "../css/slides.scss"



const Layout = ({ children , color, pageTitle}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

const schema = {
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "Celebrand",
  "alternateName": "celebrand",
  "url": "https://www.celebrand.com",
  "description": data.site.siteMetadata.description,
  // "logo": "https://d-wdesign.com/static/logo-4d8d3da1811ecc735168581dd1114e25.png",
  "sameAs": [
    "https://www.instagram.com/celebrand/",
    "https://www.facebook.com/celebrand.us",
    "https://www.linkedin.com/company/celebrand"
    ]
  }

  return (
    <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <div>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}
      title={pageTitle || data.site.siteMetadata.title}
      >

        <meta property="og:title" content={pageTitle ? pageTitle : data.site.siteMetadata.title} />
        
        <link rel="icon" href={favicon} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta name="description" content={data.site.siteMetadata.description} />

        <title>{pageTitle || data.site.siteMetadata.title}</title>

        {schema && <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>}
      </Helmet>
        <Header color={color} />
        <main className="main-sections">{children}</main>
      </div>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
