import React from 'react'
import styled from 'styled-components';
import {Link} from 'gatsby';

import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';

const Container = styled.div`
  margin-bottom: 40px;
  width: 45rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;

  @media ${props => props.theme.breaks.down('md')} {
    margin-bottom: 0;
    width: 100%;
  }
`

const Contact = styled.div`
  border: 1px solid ${props => props.color ? props.color : props.theme.colors.dark};
  color: ${props => props.color ? props.color : props.theme.colors.dark};
  width: 10rem;
  padding: 2rem 1rem;
  font-size: 1.3rem;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    background-color: ${props => props.color ? props.color : props.theme.colors.dark};
    color: ${props => props.color ? props.theme.colors.dark : props.theme.colors.body};
  }
`

const IconContainer = styled.div`
  width: 20rem;
  display: flex;

  svg {
    display: block;
    margin: 0 2rem;
    font-size: 2.2rem;
    fill: ${props => props.color ? props.color : props.theme.colors.dark}
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 10rem;
    flex-grow: 1;
    margin-left: 8rem;
  }
`
 
export default ({color}) => {
 
 return (
 <Container>
  <Link to="/get-in-touch"><Contact className="get-touch" color={color}>Get in Touch</Contact></Link>
  <IconContainer color={color}>
    <a href="https://www.instagram.com/celebrand/"><InstagramIcon className="footer-icons" /></a>
    <a href="https://www.linkedin.com/company/celebrand"><LinkedInIcon className="footer-icons" /></a>
    <a href="https://www.facebook.com/celebrand.us"><FacebookIcon className="footer-icons" /></a>
  </IconContainer>
 </Container>
 )
}