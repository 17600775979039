import React from "react"
// import RowWrapper from "../utils/rowWrapper"
import styled from 'styled-components'
import {Link} from 'gatsby';

// import Logo from "../images/celebrand-logo-v2.svg";

import Nav from './nav/Nav';


const Header = styled.header`
  width: 20%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  background-color: transparent;
  z-index: 100;
  height: 20%;
  /* display: flex;
  justify-content: space-between; */
  padding: 40px;
  padding-top: 5rem;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const Menu = styled.ul`
  li {
    color: ${props => props.color ? props.color : props.theme.colors.dark};
  }
  a, a:hover, a:focus, a:active, a:visited {
    color: ${props => props.color ? props.color : props.theme.colors.dark};
  }
`;

const ListItem = styled.li`
  font-size: 1.3rem;
  line-height: 1.7;
  text-transform: uppercase;

  & * {
  font-size: inherit;
  }
`

const Info = styled.div`
  position: fixed;
  top: 0;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 40rem;
  padding-right: 8rem;
  padding-top: 5rem;
  /* margin-right: 4rem; */
  z-index: 100;


  .main-logo {
    display: inline-block;
    height: 1.5rem;
    margin-right: 1.5rem;

    a {
      text-transform: uppercase;
      color: ${props => props.color ? props.color : props.theme.colors.dark};
      font-size: 2rem;
    }

    svg {
      font-size: 3rem;
      min-width: 150px;
    }

    svg .st0 {
      fill: ${props => props.color ? props.color : props.theme.colors.dark};
      /* stroke-width: 1px; */
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 1rem;
    padding-right: 6.5rem;
    right: 0;
    width: 100%;
    justify-content: space-between;

    .main-logo {
      margin-left: 10%;
      margin-top: 10px;
    }
  }
`

// const Hamburger = styled.div`
//   position: relative;
//   margin-top: 6px;

//   &,
//   &::before,
//   &::after {
//       display: inline-block;
//       height: 2px;
//       width: 20px;
//       border-radius: 50px;
//       background-color: rgb(85, 85, 85);
//       transition: all .2s;
//   }

//   &::before, &::after {
//       content: "";
//       position: absolute;
//       left: 0;
//   }

//   &::before { top: -5px; }
//   &::after { top: 5px; }
// `

export default ({color}) => {

  return (
    <>
    <Header id="header">
      <Menu color={color}>
        <ListItem><Link to="/long-story-short">Long Story Short</Link></ListItem>
        <ListItem><Link to="/join-the-team">join the team</Link></ListItem>
        <ListItem><Link to="/portfolio">previous work</Link></ListItem>
        <ListItem><Link to="/but-why">but why?</Link></ListItem>
        <ListItem><Link to="/services">services</Link></ListItem>
        <ListItem><Link to="/celebrand-speaks">Celebrand Speaks</Link></ListItem>
        <ListItem><Link to="/look-at-a-duck">look at a duck</Link></ListItem>
      </Menu>
    </Header>

      <Info color={color}>
        <span className="main-logo">
          <Link to="/#celebrand">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 258 29.6" style={{enableBackground:" new 0 0 258 29.6"}} xmlSpace="preserve">
          <g id="Layer_1_1_">
            <path className="st0" d="M22.3,29.6H15c-8.1,0-14.7-6.3-15-14.4c-0.2-8.1,6.3-14.8,14.4-15c0.1,0,0.2,0,0.3,0h7.6
              c0.6,0,1.2,0.5,1.2,1.2l0,0v7.5c0,0.6-0.5,1.2-1.2,1.2l0,0h-2.5c-0.6,0-1.2-0.5-1.2-1.2V5.4c0-0.2-0.1-0.3-0.3-0.3h-3.4
              c-5.5,0-10,4.5-10,10c0.1,5.3,4.5,9.6,9.8,9.6h3.6c0.2,0,0.3-0.1,0.3-0.3v-3.2c0-0.6,0.5-1.2,1.2-1.2h2.5c0.6,0,1.2,0.5,1.2,1.2
              v7.2C23.5,29,22.9,29.6,22.3,29.6L22.3,29.6z"/>
          </g>
          <g id="Layer_2_1_">
            <path className="st0" d="M32.9,5.4v6.8c0,0.2,0.1,0.3,0.3,0.3l0,0h12.4c0.6,0,1.2,0.5,1.2,1.2v2.5c0,0.6-0.5,1.2-1.2,1.2H33.2
              c-0.2,0-0.3,0.1-0.3,0.3l0,0v6.8c0,0.2,0.1,0.3,0.3,0.3h15.2c0.6,0,1.2,0.5,1.2,1.2l0,0v2.5c0,0.6-0.5,1.2-1.2,1.2l0,0H29.2
              c-0.6,0-1.2-0.5-1.2-1.2v-27c0-0.6,0.5-1.2,1.2-1.2h19.2c0.6,0,1.2,0.5,1.2,1.2l0,0V4c0,0.6-0.5,1.2-1.2,1.2H33.2
              C33,5,32.9,5.2,32.9,5.4L32.9,5.4z"/>
          </g>
          <g id="Layer_3">
            <path className="st0" d="M72.5,29.6h-17c-0.6,0-1.2-0.5-1.2-1.2l0,0v-27c0-0.6,0.5-1.2,1.2-1.2l0,0H58c0.7,0,1.2,0.5,1.2,1.2l0,0v23
              c0,0.2,0.1,0.3,0.3,0.3h9c0.2,0,0.3-0.1,0.3-0.3v-3.2c0-0.6,0.5-1.2,1.2-1.2h2.6c0.6,0,1.2,0.5,1.2,1.2v7.2
              C73.7,29,73.1,29.6,72.5,29.6L72.5,29.6z"/>
          </g>
          <g id="Layer_4">
            <path className="st0" d="M82.8,5.4v6.8c0,0.2,0.1,0.3,0.3,0.3l0,0h12.4c0.7,0,1.2,0.5,1.2,1.2l0,0v2.5c0,0.7-0.5,1.2-1.2,1.2l0,0H83.1
              c-0.2,0-0.3,0.1-0.3,0.3l0,0v6.8c0,0.2,0.1,0.3,0.3,0.3h15.2c0.6,0,1.2,0.5,1.2,1.2l0,0v2.5c0,0.6-0.5,1.2-1.2,1.2l0,0H79.1
              c-0.7,0-1.2-0.5-1.2-1.2l0,0v-27c0-0.7,0.5-1.2,1.2-1.2l0,0h19.2c0.6,0,1.2,0.5,1.2,1.2l0,0V4c0,0.5-0.5,1-1.2,1H83.1
              C82.9,5.1,82.8,5.2,82.8,5.4L82.8,5.4z"/>
          </g>
          <g id="Layer_5">
            <path className="st0" d="M122,29.6h-15.8c-0.6,0-1.2-0.5-1.2-1.1l0,0v-27c0-0.6,0.5-1.2,1.2-1.2l0,0h10.4c4.7,0,8.6,3.7,8.8,8.4
              c0,1.5-0.3,2.9-1,4.2c3.8,1.1,6.4,4.6,6.3,8.5C130.4,25.9,126.6,29.5,122,29.6z M110.2,24.7H122c2,0,3.6-1.5,3.8-3.4
              c0.1-2-1.4-3.8-3.4-3.9c-0.1,0-0.2,0-0.3,0h-11.9c-0.2,0-0.3,0.1-0.3,0.3l0,0v6.8C109.9,24.5,110,24.7,110.2,24.7z M110.2,12.4h6.5
              c2,0,3.6-1.5,3.8-3.4c0.1-2-1.4-3.8-3.4-3.9c-0.1,0-0.2,0-0.3,0h-6.6c-0.2,0-0.3,0.1-0.3,0.3v6.8C109.9,12.3,110,12.4,110.2,12.4z"
              />
          </g>
          <g id="Layer_6">
            <path className="st0" d="M152.2,23l-3.7-5.4c-0.1-0.1,0-0.2,0-0.2h0.1h2.2c4.7,0,8.5-3.6,8.8-8.2c0.2-4.7-3.5-8.7-8.3-8.9
              c-0.1,0-0.2,0-0.3,0h-15.3c-0.6,0-1.2,0.5-1.2,1.2v27c0,0.6,0.5,1.2,1.2,1.2h2.6c0.6,0,1.2-0.5,1.2-1.2l0,0V17.6
              c0-0.1,0.1-0.3,0.3-0.3c1.5-0.2,3,0.4,3.8,1.7l4.6,6.8c1.6,2.4,4.3,3.8,7.1,3.8h2c0.6,0,1.2-0.5,1.2-1.2l0,0v-2.5
              c0-0.6-0.5-1.2-1.2-1.2h-2C154,24.7,152.9,24.1,152.2,23z M139.5,12.1V5.4c0-0.2,0.1-0.3,0.3-0.3H151c2,0,3.6,1.5,3.8,3.4
              c0.1,2-1.4,3.8-3.4,3.9c-0.1,0-0.2,0-0.3,0h-11.4C139.6,12.4,139.5,12.3,139.5,12.1z"/>
          </g>
          <g id="Layer_7">
            <path className="st0" d="M175.5,0.2c-6.5,0.3-11.6,5.7-11.6,12.2v16c0,0.6,0.5,1.2,1.2,1.2l0,0h2.6c0.6,0,1.2-0.5,1.2-1.2l0,0V22
              c0-0.2,0.1-0.3,0.3-0.3l0,0h13.5c0.2,0,0.3,0.1,0.3,0.3l0,0v6.4c0,0.7,0.5,1.2,1.2,1.2h2.5c0.7,0,1.2-0.5,1.2-1.2V12.1
              c0-6.6-5.4-12-12.1-11.9C175.7,0.2,175.6,0.2,175.5,0.2z M168.8,16.5v-4.1c0-4,3.2-7.2,7.2-7.2l0,0c3.8,0.1,6.9,3.2,6.9,7v4.3
              c0,0.2-0.1,0.3-0.3,0.3h-13.5C169,16.7,168.8,16.6,168.8,16.5L168.8,16.5z"/>
          </g>
          <g id="Layer_8">
            <path className="st0" d="M222.1,28.4c0,0.6-0.5,1.2-1.2,1.2h-1.5c-5,0-9.6-3-11.5-7.7c-5.4-13-5.6-14.9-8.6-16.2
              c-0.1-0.1-0.2,0-0.3,0.1v0.1v22.5c0,0.6-0.5,1.2-1.2,1.2l0,0h-2.5c-0.7,0-1.2-0.5-1.2-1.2v-27c0-0.7,0.5-1.2,1.2-1.2h1.3
              c4.6,0,8.8,2.8,10.6,7c5.3,12.7,5.7,15.6,9.7,17c0.1,0,0.3,0,0.3-0.2c0,0,0,0,0-0.1V1.4c0-0.6,0.5-1.2,1.2-1.2l0,0h2.6
              c0.6,0,1.2,0.5,1.2,1.2L222.1,28.4z"/>
          </g>
          <g id="Layer_9">
            <path className="st0" d="M237.1,29.6h-6.7c-0.6,0-1.2-0.5-1.2-1.2l0,0v-27c0-0.6,0.5-1.2,1.2-1.2l0,0h6.9c8.1,0,14.7,6.6,14.7,14.7
              c0,0.1,0,0.2,0,0.3C251.7,23.2,245.1,29.6,237.1,29.6z M234.4,24.7h2.7c5.5,0,10-4.5,10-10c-0.1-5.3-4.5-9.6-9.8-9.6h-2.9
              c-0.2,0-0.3,0.1-0.3,0.3v19C234.1,24.5,234.2,24.7,234.4,24.7L234.4,24.7z"/>
          </g>
          {/* <g id="Layer_10">
            <path class="st1" d="M255.8,3.3l-0.3-0.5l0,0h0.2c0.4,0,0.8-0.3,0.9-0.7c0-0.4-0.3-0.8-0.7-0.9c-0.1,0-0.1,0-0.2,0h-1.4
              c-0.1,0-0.1,0-0.1,0.1v2.5c0,0.1,0,0.1,0.1,0.1l0,0h0.2c0.1,0,0.1,0,0.1-0.1l0,0v-1l0,0c0.1,0,0.3,0,0.4,0.1l0.4,0.6
              c0.1,0.2,0.4,0.3,0.6,0.3h0.2c0.1,0,0.1,0,0.1-0.1l0,0V3.6c0-0.1,0-0.1-0.1-0.1H256C256,3.5,255.9,3.4,255.8,3.3z M254.7,2.3V1.7
              l0,0h1c0.2,0,0.3,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.4l0,0L254.7,2.3C254.7,2.4,254.7,2.3,254.7,2.3L254.7,2.3z"/>
            <path class="st1" d="M255.4,5.2c-1.4,0-2.6-1.2-2.6-2.6S254,0,255.4,0c1.4,0,2.6,1.2,2.6,2.6C258,4.1,256.8,5.2,255.4,5.2
              L255.4,5.2z M255.4,0.4c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S256.6,0.4,255.4,0.4L255.4,0.4z"/>
          </g> */}
          </svg>
          </Link>
        </span>
        {/* <Hamburger /> */}
        <Nav color={color} />
      </Info>
    </>
)
}

