import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'

import context from '../../context';
import {Grid} from '@material-ui/core';


const List = styled.ul`
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
  width: 100%;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  padding-left: 10%;

  /* justify-content: start; */
  
  a {
      text-decoration: none;
      color: #fff;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }

`

const ListItem = styled.li`
  cursor: pointer;
  font-size: 9rem;
  display: block;
  margin: 4rem;
  color: ${props => props.theme.colors.primary};
  /* visibility: hidden; */

  a * {
    color: rgba(50, 48, 49, .3);
 
  }

  a:hover *, a:active *, a.active * {
    color: ${props => props.theme.colors.primary};
  }

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const Title = styled.h1`
  font-size: 9rem;
  letter-spacing: -3px;
  text-transform: uppercase;
  transition: color .3s;

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 8rem;
  }
`

const TitleSpan = styled.span`
  font-weight: 300;
  color: inherit;
  font-size: inherit;
`

const MobileNav = styled.div`

`

const MobileListItem = styled.li`
  cursor: pointer;
  font-size: 5rem;
  display: block;
  margin: 2rem 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  padding-bottom: 10px;
  /* visibility: hidden; */

  a {
    font-size: 5rem;
    text-transform: uppercase;
  }

  .light {
    font-weight: 300;
    color: inherit;
    font-size: inherit;
    text-transform: uppercase;
  }

  a * {
    color: rgba(50, 48, 49, .3);
  }

  a:hover *, a:active *, a.active * {
    color: ${props => props.theme.colors.primary};
  }

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    font-size: 3.5rem;
    a {
    font-size: 3.5rem;
    }
  }
`

const PageLinksMobile = styled.div`
  padding: 50px 20px 0 20px;
  text-align: center;

  a {
    text-transform: uppercase;
    color: ${props => props.theme.colors.dark};
    cursor: pointer;
    font-size: 1.5rem;
  }

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    padding-top: 20px;
  }
`
 
export default ({active}) => {

  const titles = [
    {
    title: 'celebrand.',
    mobile: 'celebrand.',
    span: 'this is',
    // color: active === "1" ? "" : "rgba(50, 48, 49, .3)",
    href: '/#celebrand'
  },
  {
    title: 'branding.',
    mobile: 'branding.',
    span: 'this is',
    // color: active === "2" ? "" : "rgba(50, 48, 49, .3)",
    href: '/#branding'
  },
  {
    title: 'we can do.',
    mobile: 'what we can do',
    span: 'this is what',
    // color: active === "3" ? "" : "rgba(50, 48, 49, .3)",
    href: '/#we-can'
  }]

  const isActive = ({ location, href }) => {
    return `${location.pathname}${location.hash}` === href ? { className: "active" } : {}
  }
 
 return (
  <List className="nav_list">
    {titles.map(({title, span, color, href}, i) => 
    <ListItem className="nav-list-item" key={i}>
      <Link to={href} onMouseDown={() => context.start = true} getProps={isActive}>
        <Title>
          <TitleSpan>
            {span} <br />
          </TitleSpan>
          {title}
        </Title>
      </Link>
    </ListItem>)
    }
    <MobileNav>
      <MobileListItem className="nav-list-item">
        <span className="light">This is</span>
      </MobileListItem>
    {titles.map(({mobile, color, href}, i) => 
    <MobileListItem className="nav-list-item" key={i}>
      <Link to={href} onMouseDown={() => context.start = true} getProps={isActive}>
          {mobile}
      </Link>
    </MobileListItem>)
    }
      <PageLinksMobile>
        <Grid container spacing={3} justify="center">
          <Grid item xs={6}><Link to="/long-story-short">Long story Short</Link></Grid>
          <Grid item xs={6}><Link to="/join-the-team">Join the team</Link></Grid>
          <Grid item xs={6}><Link to="/portfolio">Previous Work</Link></Grid>
          <Grid item xs={6}><Link to="/but-why">But Why?</Link></Grid>
          <Grid item xs={6}><Link to="/services">Services</Link></Grid>
          <Grid item xs={6}><Link to="/celebrand-speaks">Celebrand Speaks</Link></Grid>
          <Grid item xs={6}><Link to="/look-at-a-duck">Look at a Duck</Link></Grid>
        </Grid>
      </PageLinksMobile>
    </MobileNav>
  </List>
 )
}