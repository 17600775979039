import {createMuiTheme} from '@material-ui/core'
const size = {
  smp: '450px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

export const theme =  createMuiTheme({
  colors: {
    primary: '#FFF',
    body: '#FEC340',
    bodyLight: '#fece67',
    dark: 'rgb(50, 48, 49)',
  },
  breaks: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    bewteen: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  palette: {
    primary: {
      main: 'rgb(50, 48, 49)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FEC340',
      contrastText: '#fff'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      smp: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '6rem'
    }
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
    htmlFontSize: 10,
    h6: {
      fontSize: '1.6rem'
    },
    h5: {
      fontSize: '2.1rem'
    },
    body1: {
      fontSize: '1.4rem',
    },
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
})

// console.log(theme);